<template>
  <div class="ExamRanking">
    <detailsHeader
      ref="detailsHeader"
      :router-url="$route.query.routerName || '/exam/unionExam'"
      :query="{
        examId: $route.query.examId,
      }"
    ></detailsHeader>
    <div class="rank-tab-box">
      <!-- <div ref="tips" class="rank-tips">
        <div>
          <i class="el-icon-question"></i>
          提示：榜单所示成绩均为各学科原始卷面成绩
        </div>
        <i class="el-icon-close" @click="$refs.tips.style.display = 'none'"></i>
      </div> -->
      <tipsItem :tips-data="tipsData"></tipsItem>
    </div>
    <div class="check-tab-box">
      <!-- <el-tabs v-model="activeType" @tab-click="activeTypeClick">
        <el-tab-pane label="年级临时榜" name="1"></el-tab-pane>
        <el-tab-pane label="班级临时榜" name="2"></el-tab-pane>
      </el-tabs> -->
      <div class="search-box">
        <!-- {{ examInformation }} -->

        <div class="search-left">
          <div class="search-item">
            学校：
            <el-select
              v-model="search.schoolId"
              placeholder="请选择"
              filterable
              @change="getnewClassandexamroom()"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="(item, i) in schoolList"
                :key="i"
                :label="item.schoolName"
                :value="item.schoolId"
              >
              </el-option>
            </el-select>
          </div>

          <template v-if="examInformation.ruleId > 0">
            <div class="search-item">
              <template v-if="subjuectClassNumType">
                教学班：
                <el-select
                  v-model="search.subjectClassNum"
                  placeholder="请选择"
                  :disabled="!classCheckType || !search.schoolId"
                  filterable
                  @change="handleClick"
                >
                  <el-option label="全部" value=""> </el-option>
                  <el-option
                    v-for="(item, i) in subjectClassNums"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
              <template v-else>
                <template v-if="checkTypeOn == 1"> 行政班： </template>
                <template v-else> 教学班： </template>
                <el-select
                  v-model="search.classNum"
                  placeholder="请选择"
                  :disabled="!classCheckType || !search.schoolId"
                  filterable
                  @change="handleClick"
                >
                  <el-option label="全部" value=""> </el-option>
                  <el-option
                    v-for="(item, i) in classNumList"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </div>
            <div class="search-item">
              选考组合：
              <el-select
                v-model="search.combination"
                placeholder="请选择"
                :disabled="!combination"
                filterable
                clearable
                @change="combinationClick"
              >
                <el-option label="全部" value=""> </el-option>
                <template v-for="(item, i) in combinations">
                  <el-option
                    v-if="item.label.indexOf('物') != -1"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>

                <template v-for="(item, i) in combinations">
                  <el-option
                    v-if="item.label.indexOf('历') != -1"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
            </div>
          </template>
          <div
            v-if="examInformation.id && !examInformation.ruleId"
            class="search-left"
          >
            班级：
            <el-select
              v-model="search.classNum"
              placeholder="请选择"
              :disabled="!search.schoolId"
              filterable
              @change="handleClick"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="(item, i) in classNumList"
                :key="i"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div>
          <el-input
            v-model="studentName"
            placeholder="请输入考生姓名"
            @keyup.enter.native="handleClick"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="handleClick"
            >
            </i
          ></el-input>
          <div class="btn-tips">
            <el-button type="primary" @click="goError()">
              <img src="@/assets/uExam/error_score.png" alt="" />
              <img class="on" src="@/assets/uExam/error_score.png" alt="" />
              检查异常成绩
            </el-button>
            <el-button
              type="primary"
              :loading="btnLoading"
              @click="exportStudent"
            >
              <img src="@/assets/newExam/student/icon-export.svg" alt="" />
              <img
                class="on"
                src="@/assets/newExam/student/icon-export.svg"
                alt=""
              />
              导出为Excel
            </el-button>
            <tipsIcon :tips-data="tipsData"></tipsIcon>
          </div>
        </div>
      </div>

      <!-- 考生列表 -->
      <div class="table-box">
        <div class="check-type-box">
          <div
            v-for="(v, i) in checkType"
            :key="i"
            :class="{ on: checkTypeOn == v.value }"
            @click="checkTypeChange(v.value)"
          >
            {{ v.label }}
          </div>
        </div>
        <div
          v-if="checkTypeOn == 2 && subjectList.length > 0"
          class="subject-item-box"
        >
          <div
            v-for="(v, i) in subjectList"
            :key="i"
            :class="{ on: indexSubjuectId == v.subjectId }"
            @click="paperChange(v)"
          >
            {{ v.subjectName }}
          </div>
        </div>
        <!-- {{ activeType }} -->
        <el-table
          ref="multipleTable"
          :key="tableKey"
          v-loading="dataListLoading"
          border
          :data="dataList"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55" align="center"> -->
          <!-- </el-table-column> -->
          <el-table-column
            label="排名"
            prop="orderNum"
            align="center"
            show-overflow-tooltip
          >
            <!-- <template slot-scope="scope">
              {{ parseInt(scope.row.orderNum) + (pageIndex - 1) * pageSize }}
            </template> -->
          </el-table-column>
          <el-table-column align="center" prop="studentName" label="姓名">
          </el-table-column>
          <el-table-column align="center" prop="schoolName" label="学校">
          </el-table-column>
          <el-table-column align="center" prop="studentType" label="分类">
            <template slot-scope="{ row }">
              {{ row.studentType || "-" }}
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="studentExamId"
            label="考号"
            width="200px"
          >
          </el-table-column>
          <template v-if="examInformation.id && examInformation.examModel">
            <!-- {{ activeType }} -->
            <template v-if="activeType == 1">
              <el-table-column
                align="center"
                prop="classNum"
                :label="checkTypeOn == 1 ? '行政班' : '教学班'"
              >
                <template slot-scope="{ row }">
                  <template v-if="subjuectClassNumType">
                    {{ row.subjectClassNum }}
                  </template>
                  <template v-else>
                    {{ row.classNum }}
                  </template>
                </template>
              </el-table-column>
            </template>
            <template v-if="activeType == 2">
              <el-table-column align="center" label="行政班">
                <template #header>
                  <el-popover
                    v-if="!subjuectClassNumType"
                    placement="top"
                    popper-class="poper-box"
                  >
                    <el-radio-group
                      v-model="search.classNum"
                      class="el-search-box"
                      @change="handleClick"
                    >
                      <!-- <el-radio label="">全部 </el-radio> -->
                      <el-radio
                        v-for="(item, i) in classNumList"
                        :key="i"
                        :label="item.value"
                      >
                        {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                    <span slot="reference" class="tab-btn-box">
                      <template v-if="checkTypeOn == 1"> 行政班 </template>
                      <template v-else> 教学班 </template>
                      <img
                        v-if="search.classNum"
                        src="@/assets/newExam/sizer-on.svg"
                        alt=""
                      />
                      <img v-else src="@/assets/newExam/sizer.svg" alt="" />
                    </span>
                  </el-popover>
                  <el-popover v-else placement="top" popper-class="poper-box">
                    <el-radio-group
                      v-model="search.subjectClassNum"
                      class="el-search-box"
                      @change="handleClick"
                    >
                      <!-- <el-radio label="">全部 </el-radio> -->
                      <el-radio
                        v-for="(item, i) in subjectClassNums"
                        :key="i"
                        :label="item.value"
                      >
                        {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                    <span slot="reference" class="tab-btn-box">
                      教学班
                      <img
                        v-if="search.subjectClassNum"
                        src="@/assets/newExam/sizer-on.svg"
                        alt=""
                      />
                      <img v-else src="@/assets/newExam/sizer.svg" alt="" />
                    </span>
                  </el-popover>
                </template>
                <template slot-scope="{ row }">
                  <template v-if="subjuectClassNumType">
                    {{ row.subjectClassNum }}
                  </template>
                  <template v-else>
                    {{ row.classNum }}
                  </template>
                </template>
              </el-table-column>
            </template>

            <el-table-column
              v-if="combination"
              align="center"
              prop="combination"
              label="选考组合"
            >
              <template #header>
                <el-popover
                  placement="top"
                  width="118"
                  popper-class="poper-box"
                >
                  <el-radio-group
                    v-model="search.combination"
                    class="el-search-box"
                    @change="combinationClick"
                  >
                    <el-radio label=""> 全部 </el-radio>
                    <template v-for="(item, i) in combinations">
                      <el-radio
                        v-if="item.label.indexOf('物') != -1"
                        :key="i"
                        :label="item.value"
                      >
                        {{ item.label }}
                      </el-radio>
                    </template>
                    <hr />
                    <template v-for="(item, i) in combinations">
                      <el-radio
                        v-if="item.label.indexOf('历') != -1"
                        :key="i"
                        :label="item.value"
                        >{{ item.label }}</el-radio
                      >
                    </template>
                  </el-radio-group>

                  <span slot="reference" type="text" class="tab-btn-box">
                    选考组合
                    <img
                      v-if="search.combination"
                      src="@/assets/newExam/sizer-on.svg"
                      alt=""
                    />
                    <img v-else src="@/assets/newExam/sizer.svg" alt="" />
                  </span>
                </el-popover>
              </template>
            </el-table-column>
          </template>
          <template v-if="examInformation.id && !examInformation.examModel">
            <el-table-column
              v-if="classCheckType"
              align="center"
              prop="classNum"
              label="班级"
            >
            </el-table-column>
          </template>

          <template v-if="getquestionShow()">
            <el-table-column
              v-for="(v, i) in titleList"
              :key="i"
              header-align="center"
              align="center"
              :label="v.subjectName"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.subjectScoreList[i].score == -1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="非选考科目"
                    placement="top"
                  >
                    <i class="el-icon-minus"></i>
                  </el-tooltip>
                </template>
                <template v-if="scope.row.subjectScoreList[i].score == -2">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="暂无查看权限"
                    placement="top"
                  >
                    <span>&nbsp;</span>
                    <!-- ? -->
                    <!-- <i class="el-icon-question"></i> -->
                  </el-tooltip>
                </template>
                <template
                  v-if="
                    scope.row.subjectScoreList[i].score != -1 &&
                    scope.row.subjectScoreList[i].score != -2
                  "
                >
                  <span class="total-score" @click="showCard(scope.row, v)">
                    {{ scope.row.subjectScoreList[i].score }}
                  </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              :key="scoreKey + 'scoreKey'"
              prop="totalScore"
              header-align="center"
              align="center"
              width="110px"
              label="全科总分"
            >
              <template slot-scope="{ row }">
                <!-- {{ row.totalScore }} -->
                <template v-if="row.totalScore == -2">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="暂无查看权限"
                    placement="top"
                  >
                    <span>&nbsp;</span>
                    <!-- <span>?</span> -->
                    <!-- <i class="el-icon-question"></i> -->
                  </el-tooltip>
                </template>
                <template v-else>
                  {{ row.totalScore }}
                </template>
              </template>
            </el-table-column>
          </template>

          <template v-else>
            <el-table-column align="center" prop="objScore" label="客观题">
              <template slot-scope="scope">
                {{ scope.row.objScore === null ? "-" : scope.row.objScore }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="subScore" label="主观题">
              <template slot-scope="scope">
                {{ scope.row.subScore === null ? "-" : scope.row.subScore }}
              </template>
            </el-table-column>
            <el-table-column
              :key="scoreKey"
              align="center"
              label="单科总分"
              prop="subjectScore"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.subjectScore == -1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="非选考科目"
                    placement="top"
                  >
                    <i class="el-icon-minus"></i>
                  </el-tooltip>
                </template>
                <template v-if="scope.row.subjectScore == -2">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="暂无查看权限"
                    placement="top"
                  >
                    <i class="el-icon-minus"></i>
                  </el-tooltip>
                </template>
                <template
                  v-if="
                    scope.row.subjectScore != -2 && scope.row.subjectScore != -1
                  "
                >
                  <span class="total-score" @click="showCard(scope.row)">{{
                    scope.row.subjectScore
                  }}</span>
                </template>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <el-pagination
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
const checkType = [
  {
    value: 1,
    label: "按总分",
  },
  {
    value: 2,
    label: "按科目",
  },
];
import { getObj, schoollistbyexamid } from "@/core/api/exam/exam";
import {
  provisionalList,
  importProvisionalList,
  getSubjectlist,
} from "@/core/api/exam/examRank";
import { getclassandexamroom } from "@/core/api/exam/examstudent";
import {
  studentTypeList,
  subjectOptions,
  optionalSubject,
} from "@/core/util/constdata";
import detailsHeader from "@/components/detailsHeader.vue";
import tipsItem from "@/components/tipsItem.vue";
import tipsIcon from "@/components/tipsIcon.vue";
import {
  // delArrFromArr,
  // pushArrToArrByUnique,
  fileDownloadByUrl,
} from "@/core/util/util";
export default {
  name: "ExamRanking",
  components: { detailsHeader, tipsItem, tipsIcon },
  data() {
    const { roleId } = JSON.parse(
      localStorage.getItem("jzjx-userInfo")
    ).content;
    return {
      tipsData: [
        {
          title: `提示：榜单所示成绩均为各学科原始卷面成绩`,
          content: "",
          type: "primary",
        },
      ],
      tableKey: 1,
      optionalSubject: optionalSubject,
      checkType: checkType,
      checkTypeOn: 1,
      indexSubjuectId: "",
      activeType: "1",
      scoreKey: 1,
      classCheckType: true,
      combination: true,
      classListType: true,
      subjuectClassNumType: false,
      subjectClassNums: [],
      classList: [],
      subjectIdType: true,
      combinations: [],
      btnLoading: false,
      examInformation: {},
      subjectOptions: JSON.parse(JSON.stringify(subjectOptions)),
      studentName: "",
      search: {
        classNum: "",
        subjectId: "",
        combination: "",
        subjectClassNum: "",
        schoolId: "",
      },
      studentTypeList: studentTypeList,
      studentExamRoomsList: [],
      classNumList: [],

      // 列表
      dataList: [],
      paperList: [],
      subjectList: [],
      titleList: [],
      dataListLoading: false,
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      CDN: "https://static.wtjy.com/",
      schoolList: [],
      roleId: roleId,
    };
  },

  async created() {
    await this.getExamInfo();
    await this.getSub();
    this.getSchoolList();
    this.getDataList();
    this.getnewClassandexamroom(1);
  },
  methods: {
    async getSub() {
      let data = {
        examId: this.$route.query.examId,
      };
      await getSubjectlist(data)
        .then((res) => {
          this.paperList = res.data.data.map((item) => {
            item.paperId = item.examPaperId;
            return item;
          });

          // 新高考  给选考科目添加标志位
          if (this.examInformation.ruleId) {
            this.paperList.map((item) => {
              this.subjectOptions.map((index) => {
                if (item.subjectId == index.value) {
                  if (index.abbreviation) {
                    item.abbreviation = index.abbreviation;
                  }
                }
              });
            });
          }
          this.subjectList = JSON.parse(JSON.stringify(this.paperList));
        })
        .catch(() => {});
    },
    activeTypeClick() {
      this.tableKey = new Date().getTime();
      this.checkTypeOn = 1;
      this.indexSubjuectId = this.subjectList[0].subjectId;
      this.paperChange(this.subjectList[0]);
    },
    // 切换总分
    checkTypeChange(value) {
      this.checkTypeOn = value;
      this.indexSubjuectId = this.subjectList[0].subjectId;
      this.paperChange(this.subjectList[0]);
    },
    //  科目
    paperChange(value) {
      this.indexSubjuectId = value.subjectId;
      if (value.abbreviation) {
        if (this.copycombinations) {
          let combinations = this.copycombinations.filter(
            (item) => item.label.indexOf(value.abbreviation) != -1
          );

          this.combinations = JSON.parse(JSON.stringify(combinations));
          let array = this.combinations.map((item) => item.label);
          if (array.indexOf(this.search.combination) == -1) {
            this.search.combination = "";
          }
        }

        this.subjuectClassNumType = true;
        this.getnewClassandexamroom();
      } else {
        if (this.examInformation.ruleId) {
          if (this.copycombinations) {
            this.combinations = JSON.parse(
              JSON.stringify(this.copycombinations)
            );
          }
        }

        this.subjuectClassNumType = false;
        this.handleClick();
      }

      // console.log(this.subjuectClassNumType);
    },
    getquestionShow() {
      let type = false;
      if (this.checkTypeOn == 1) {
        type = true;
      }
      return type;
    },
    async getSchoolList() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });
      this.schoolList = res.data.data;
    },
    // 答题卡页面
    showCard(val, item) {
      let paperId = "";

      if (this.checkTypeOn == 1) {
        paperId = item.paperId;
      } else {
        let paperList = this.paperList.filter(
          (index) => index.subjectId == this.indexSubjuectId
        );

        if (paperList.length > 0) {
          paperId = paperList[0].examPaperId;
        } else {
          paperId = this.paperList[0].examPaperId;
        }
      }

      let data = {
        pageIndex: this.pageIndex,
        studentExamId: val.studentExamId,
        studentId: val.studentId,
        examId: this.$route.query.examId,
        examPaperId: paperId,

        urlName: "unionExamRanking",
      };

      this.$router.push({
        name: "unionExamStudentScore",
        query: data,
      });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getDataList();
      // console.log(`当前页: ${val}`);
    },
    // 获取科目班级
    getnewClassandexamroom(type) {
      let data = {
        subjectId: this.indexSubjuectId,
        schoolId: this.search.schoolId,
      };
      this.search.subjectClassNum = "";
      this.search.classNum = "";
      getclassandexamroom(this.$route.query.examId, data).then((res) => {
        let { subjectClassNums, classNums } = res.data.data;
        this.classNumList = [];
        this.subjectClassNums = [];
        if (subjectClassNums) {
          subjectClassNums.map((item) => {
            this.subjectClassNums.push({
              value: item,
              label: item,
            });
          });
        }

        if (classNums) {
          classNums.forEach((index) => {
            this.classNumList.push({
              value: index,
              label: index,
            });
          });
        }
        if (type == 1) return;
        this.combinations = [];
        if (this.examInformation.examModel) {
          let { combinations } = res.data.data;
          combinations.map((item) => {
            this.combinations.push({
              value: item,
              label: item,
            });
          });
          this.copycombinations = JSON.parse(JSON.stringify(this.combinations));
        }
        this.handleClick();
      });
    },

    setTitleDataList() {
      this.titleList = [];
      if (this.dataList.length > 0) {
        this.titleList = this.dataList[0].subjectScoreList;
      }
      this.titleList.map((item) => {
        this.paperList.map((index) => {
          if (item.subjectId == index.subjectId) {
            item.paperId = index.paperId;
          }
        });
      });

      // 组合好需要显示的科目
      // this.paperList.forEach((index) => {
      //   this.subjectOptions.forEach((item) => {
      //     if (index.subjectId == item.value) {
      //       this.titleList.push({
      //         label: item.label,
      //         prop: "sub" + item.index,
      //         index: item.index,
      //         value: item.value,
      //         paperId: index.paperId,
      //       });
      //     }
      //   });
      // });
      // this.titleList.sort(function (a, b) {
      //   return a.index - b.index;
      // });
      // console.log(this.titleList);
    },
    getDataList() {
      this.dataListLoading = true;
      let data = {
        examId: this.$route.query.examId,
        current: this.pageIndex,
        size: this.pageSize,
        studentName: this.studentName,
      };
      Object.assign(data, this.search);
      data = JSON.parse(JSON.stringify(data));
      // console.log(data);
      // if (this.activeType == 1) {
      //   data.classNum = "";
      //   data.subjectClassNum = "";
      // }
      if (this.checkTypeOn == 2) {
        data.subjectId = this.indexSubjuectId;
      }
      // 判断下科目  是不是选考科目
      if (data.subjectId && this.examInformation.ruleId > 0) {
        if (this.optionalSubject.indexOf(data.subjectId) == -1) {
          data.subjectClassNum = "";
        } else {
          data.classNum = "";
        }
      }
      if (this.examInformation.ruleId == 0) {
        delete data.subjectClassNum;
      }
      provisionalList(data)
        .then((res) => {
          this.dataList = res.data.data.records;
          this.total = res.data.data.total;
          this.dataListLoading = false;
          //目前当场考试存在多少科目
          this.setTitleDataList();
        })
        .catch(() => {
          this.dataListLoading = false;
        });
    },
    //获取考试基本信息
    async getExamInfo() {
      await getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
      });
    },
    goBack() {
      let { routerName } = this.$route.query;
      if (routerName) {
        this.$router.push({
          name: routerName,
          query: { examId: this.$route.query.examId },
        });
      } else {
        this.$router.push({
          name: "examlist",
          query: { examId: this.$route.query.examId },
        });
      }
    },
    combinationClick() {
      let { combination } = this.search;
      if (combination) {
        combination = combination.split("");
        this.subjectList = this.paperList.filter((item) => {
          if (!item.abbreviation) {
            return item;
          } else {
            if (combination.indexOf(item.abbreviation) != -1) {
              return item;
            } else {
              // return false;
            }
          }
        });
      } else {
        this.subjectList = JSON.parse(JSON.stringify(this.paperList));
      }
      this.handleClick();
    },

    handleClick() {
      this.scoreKey = new Date().getTime();
      this.pageIndex = 1;
      this.getDataList();
    },
    goError() {
      this.$router.push({
        name: "ExamAbnormalScore",
        query: this.$route.query,
      });
    },
    exportStudent() {
      this.btnLoading = true;
      let data = {
        examId: this.$route.query.examId,
        current: this.pageIndex,
        size: this.pageSize,
        studentName: this.studentName,
      };
      Object.assign(data, this.search);
      data = JSON.parse(JSON.stringify(data));
      // console.log(data);
      // if (this.activeType == 1) {
      //   data.classNum = "";
      //   data.subjectClassNum = "";
      // }
      if (this.checkTypeOn == 2) {
        data.subjectId = this.indexSubjuectId;
      }
      // 判断下科目  是不是选考科目
      if (this.examInformation.ruleId) {
        if (data.subjectId) {
          if (this.optionalSubject.indexOf(data.subjectId) == -1) {
            data.subjectClassNum = "";
          } else {
            data.classNum = "";
          }
        }
      }

      if (this.examInformation.ruleId == 0) {
        delete data.subjectClassNum;
      }

      importProvisionalList(data)
        .then((res) => {
          this.btnLoading = false;
          let url = this.CDN + res.data.data.url;
          let name = res.data.data.name;
          fileDownloadByUrl(url, name);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.ExamRanking {
  .el-table__header th {
    background-color: #ffffff !important;
  }
  .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
  .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
  .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
  .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 18px;
  }
}
.poper-box {
  text-align: center;
  min-width: auto;
  hr {
    width: 100%;
    height: 1px;
    background-color: #dddddd;
    margin-bottom: 16px;
    border: 0;
  }
  .el-search-box {
    padding-left: 6px;
    .el-radio {
      text-align: left;
      margin-right: 0;
      display: block;
      margin-bottom: 16px;
    }
  }
}
.el-popover-edit.el-popover {
  background: #303133;
  color: #fff;
  padding: 6px 9px;

  .popper__arrow {
    &::after {
      border-top-color: #303133;
      border-bottom-color: #303133;
    }
  }
}
.edit-tips-btn-box {
  width: 32px;
  height: 32px;
  background: #f3faff;
  border-radius: 4px;
  margin-left: 12px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  i {
    color: #2474ed;
  }
}
</style>
<style lang="scss" scoped>
.ExamRanking {
  padding: 24px 0 60px;

  .total-score {
    color: $primary-color;
    cursor: pointer;
  }
  .check-box {
    display: flex;
    align-items: center;
    padding: 16px 18px;
    user-select: none;
    .check-box-item {
      display: flex;
      align-items: center;
      > div {
        margin-right: 18px;
        &:last-child {
          margin-right: 0px;
        }
      }
      .el-select {
        width: 100px;
      }
      .subject-box {
        display: flex;
        align-items: center;
        .fg-box {
          width: 1px;
          height: 38px;
          background: #d5d6db;
          opacity: 0.36;
          margin: 0 16px;
        }
        .check-box-list {
          width: 142px;
          height: 46px;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          justify-content: space-between;
        }
        .el-checkbox {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
    }
  }
  // .teacher-color{

  // }
  .total-score-ff {
    cursor: not-allowed;
    color: #777473;
  }
  .check-tab-box {
    background-color: #ffffff;
    .table-box {
      padding: 0 18px 18px;
    }
  }
  .rank-tab-box {
    margin-top: 18px;
    background-color: #ffffff;
    padding: 18px 18px 5px;
    .rank-tab-box-search-item {
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search-filter {
        display: flex;
        .el-button {
          margin-left: 12px;
        }
      }
    }

    .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      margin-right: 0;
    }

    .rank-tips {
      background: #f3f8ff;
      // border: 1px dashed #ff7575;
      padding: 10px;
      color: #777473;
      display: flex;
      align-items: center;
      // margin-bottom: 18px;
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
      i {
        color: #2474ed;
      }
      .el-icon-close {
        cursor: pointer;
        color: #797984;
      }
      // img {
      //   margin-right: 6px;
      // }

      span {
        // width: 13px;
        // height: 13px;
        font-size: 12px;
        padding: 0px 6px;
        background: #f17e5e;
        border-radius: 50%;
        color: #ffffff;
        letter-spacing: 0px;
      }
    }
  }
  .tab-btn-box {
    color: #0a1119;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .subject-item-box {
    background: #ffffff;
    border: 1px solid #ebecee;
    display: flex;
    padding: 12px 18px;
    border-bottom: 0;
    div {
      padding: 6px 18px;
      border-radius: 4px;
      border: 1px solid #d5d6db;
      margin-right: 8px;
      cursor: pointer;
      &:hover {
        color: #2474ed;
        border-color: #2474ed;
      }

      &:last-child {
        margin-right: 0;
      }
    }
    .on {
      color: #2474ed;
      border-color: #2474ed;
    }
  }
  .check-type-box {
    display: flex;

    div {
      padding: 12px 36px;
      border: 1px solid #ebecee;
      border-bottom: 0;
      background: #f5f7fa;
      cursor: pointer;
      color: #909399;
      &:first-child {
        border-radius: 4px 0px 0px 0;
        border-right: 0;
      }
    }
    .on {
      color: #2474ed;
      background: #ffffff;
    }
  }

  .search-box {
    padding: 18px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .btn-tips {
      display: flex;
    }
    > div {
      display: flex;
      .el-button {
        margin-left: 18px;
      }
    }

    .search-item {
      font-size: 16px;
      margin-right: 18px;

      &:last-child {
        margin-right: 0;
      }
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      // margin-bottom: 24px;
      color: #080a09;
      width: 100%;
      span {
        cursor: pointer;
      }

      .el-icon-arrow-left {
        cursor: pointer;
      }
    }
  }
}
</style>
